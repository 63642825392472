import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateReview, deleteReply } from "../../../../../store/actions/index";
import Card from "../../../../../shared/components/Elements/Card/Card";
import classes from "./Review.module.css";
import FiveStars from "../../../../../shared/components/Elements/FiveStars/FiveStars";
import Actions from "./components/Actions/Actions";
import SendReply from "./components/SendReply/SendReply";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ReplyText from "./components/ReplyText/ReplyText";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { IReview } from "../../../../../store/reducers/latestReviews";
import Conversion from "./components/Conversion/Conversion";
import Feedback from "./components/Feedback/Feedback";
import Image from "./components/Image/Image";
import Video from "./components/Video/Video";

interface IReviewProps {
  review: IReview;
}

const Review: React.FC<IReviewProps> = (props) => {
  const [isReplying, setIsReplying] = useState(false);

  const {
    _id,
    isVerified,
    reviewer,
    starRating,
    createdAt,
    feedback,
    email,
    product,
    isHidden,
    drivenSales,
    clickConversion,
    reply,
    image,
    video,
  } = props.review;

  const dispatch = useDispatch();
  const hiddenToggleHandler = () => {
    dispatch(updateReview(_id, { isHidden: !isHidden }));
  };
  const sendReplyHandler = (reply: string) => {
    dispatch(updateReview(_id, { reply }));
    setIsReplying(false);
  };
  const deleteReplyHandler = () => {
    dispatch(deleteReply(_id));
  };

  const visibleIcon = isHidden ? <VisibilityOffIcon /> : <VisibilityIcon />;

  const textAndButtons = (
    <div className={classes.Review}>
      <div className={classes.Header}>
        <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
          {reviewer}
        </a>
        <span> đã đánh giá </span>
        <a href={product.url} target="_blank" rel="noreferrer">
          {product.title}
        </a>
        <span className={classes.VisibleIcon}>{visibleIcon}</span>
      </div>

      <div className={classes.Rating}>
        <FiveStars rating={starRating} />
        <span className={classes.CreatedAt}>{createdAt}</span>
        {isVerified && <CheckCircleIcon />}
      </div>

      <div className={classes.Feedback}>
        <Feedback text={feedback} />
      </div>

      {isReplying && (
        <SendReply
          onCancel={() => setIsReplying(false)}
          onSend={(reply: string) => {
            sendReplyHandler(reply);
          }}
        />
      )}

      {reply && (
        <ReplyText
          onEditClick={() => setIsReplying(true)}
          onDeleteClick={deleteReplyHandler}
        >
          {reply}
        </ReplyText>
      )}

      <Actions
        reviewId={_id}
        isReviewHidden={isHidden}
        isReviewReplied={!!reply}
        isReviewVerified={isVerified}
        onHideToggleClick={hiddenToggleHandler}
        onReplyClick={() => setIsReplying(true)}
      />

      {clickConversion || drivenSales.length ? (
        <Conversion
          clickConversion={clickConversion}
          drivenSales={drivenSales}
        />
      ) : null}
    </div>
  );

  const reviewComponent = (
    <div className="row">
      <div
        className={[
          image || video ? "column-60" : "",
          classes.TextButtonArea,
        ].join(" ")}
      >
        {textAndButtons}
      </div>
      {image && (
        <div className={["column-40", classes.Image].join(" ")}>
          <Image
            image500Url={image.image500Url}
            image720Url={image.image720Url}
          />
        </div>
      )}
      {video && (
        <div className={["column-40", classes.Image].join(" ")}>
          <Video url={video.url} thumbnailUrl={video.thumbnailUrl} />
        </div>
      )}
    </div>
  );

  return (
    <div className={classes.ReviewCard}>
      <Card>{reviewComponent}</Card>
    </div>
  );
};

export default Review;
