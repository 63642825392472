import React from "react";
import Card from "../../../../../shared/components/Elements/Card/Card";
import classes from "./Instruction.module.css";
import EditCodeImg from "../../../../../assets/images/codeInstruction/EditCode.png";
import Step1Img from "../../../../../assets/images/codeInstruction/step1.png";
import Step2Img from "../../../../../assets/images/codeInstruction/step2.png";
import Step3Img from "../../../../../assets/images/codeInstruction/step3.png";
import Step4Img from "../../../../../assets/images/codeInstruction/step4.png";
import Step5Img from "../../../../../assets/images/codeInstruction/step5.png";

const Instruction = () => {
  return (
    <div className={classes.Instruction}>
      <div>
        Lưu ý: Nếu như trong quá trình bạn gặp bất cứ bước nào đã có sẵn code /
        đoạn mã từ Ảnh Nổi bạn có thể bỏ qua.
      </div>
      <div className={classes.Step}>Bước 1:</div>
      <div>Vào mục WEBSITE trong KÊNH BÁN HÀNG, chọn CHỈNH SỬA CODE</div>
      <img className={classes.Image} alt="editCode" src={EditCodeImg} />
      <div>Copy đoạn mã sau:</div>
      <Card>
        <code>{`<div id="anhnoiReviews" data-product-id="{{product.id}}" class="page-width"></div>`}</code>
      </Card>
      <div>
        Tìm file "product.liquid". Tìm kiếm trong file bằng cách click vào trang
        code, bấm tổ hợp Ctrl + F trên Windows hoặc Command + F trên Mac. Copy
        dòng code này "settings.productspg_related_products" vào thanh tìm kiếm
        và dán đoạn mã bước 1 trên lên phía trên dòng code.
      </div>
      <img className={classes.Image} alt="step1" src={Step1Img} />

      <div className={classes.Step}>Bước 2:</div>
      <div>Copy đoạn mã sau:</div>
      <Card>
        <code>{`<a href="#anhnoiReviews"><div class="anhnoi-rating-summary" id="anhnoiRatingSummary" data-product-id="{{product.id}}" data-rating="{{ product.metafields.anhnoiRatingSummary.averageRating }}" data-num-reviews="{{ product.metafields.anhnoiRatingSummary.numberOfReviews }}" ></div></a>`}</code>
      </Card>
      <div>
        Vẫn trong file "product.liquid", tìm dòng code "
        {"<h1>{{ product.title }}</h1>"}", thông thường bạn sẽ tìm thấy 3 dòng
        code như vậy. Dán đoạn mã bước 2 vào sau mỗi dòng code trên.
      </div>
      <img className={classes.Image} alt="step2" src={Step2Img} />
      <div className={classes.Step}>Bước 3:</div>
      <div>
        Tìm file "product-loop.liquid", tìm dòng code "{`{{ product.title }}`}",
        dán đoạn mã bước 2 dưới thẻ "h3".
      </div>
      <img className={classes.Image} alt="step3" src={Step3Img} />

      <div className={classes.Step}>Bước 4:</div>
      <div>Copy đoạn mã sau:</div>
      <Card>
        <code>{`{{ shop.metafields.anhnoi.global_html_head }}`}</code>
      </Card>
      <div>
        Tìm file "theme.liquid", tìm dòng code "{`</head>`}", dán đoạn mã trên
        lên phía trên dòng code.
      </div>
      <img className={classes.Image} alt="step4" src={Step4Img} />

      <div className={classes.Step}>Bước 5:</div>
      <div>Copy đoạn mã sau:</div>
      <Card>
        <code>{`{{ shop.metafields.anhnoi.global_html_body }}`}</code>
      </Card>
      <div>
        Vẫn trong file "theme.liquid", tìm dòng code "{`</body>`}", dán đoạn mã
        trên lên phía trên dòng code.
      </div>
      <img className={classes.Image} alt="step5" src={Step5Img} />

      <div>
        Bạn đã hoàn tất! Vào bất kì sản phẩm trên website shop để kiểm tra, nếu
        không thấy phần đánh giá từ Ảnh Nổi, hãy liên lạc với chúng tôi qua địa
        chỉ email{" "}
        <span className={classes.SupportEmail}>hotro.anhnoi@gmail.com</span>
      </div>
    </div>
  );
};

export default Instruction;
