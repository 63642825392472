import React from "react";
import classes from "./IncludedConversion.module.css";
import Tooltip from "../../../../../../shared/components/Elements/Tooltip/Tooltip";
import vndFormat from "../../../../../../shared/utils/vndFormat";
import ErrorIcon from "@material-ui/icons/Error";

interface IIncludedConversionProps {
  numberOfOrders: number;
  tooltip: string;
  tooltipText: string;
  revenue: number;
  warningText?: string;
}

const IncludedConversion: React.FC<IIncludedConversionProps> = (props) => {
  return (
    <div className={classes.IncludedConversion}>
      {props.warningText && (
        <div className={classes.ErrorIcon}>
          <Tooltip text={props.warningText} position="Left">
            <ErrorIcon
              style={{
                borderRadius: "50%",
                backgroundColor: "white",
                color: "rgb(253, 190, 72)",
              }}
            />
          </Tooltip>
        </div>
      )}

      <div>
        <span className={classes.NumberOfOrders}>
          {props.numberOfOrders}
        </span>{" "}
        <Tooltip text={props.tooltipText} showBorderBottom position="Top">
          {props.tooltip}
        </Tooltip>
      </div>
      <div className={classes.Revenue}>
        {vndFormat.format(props.revenue)}
      </div>
    </div>
  );
};

export default IncludedConversion;
