import React from "react";
import classes from "./Conversion.module.css";
import Tooltip from "../../../../../../../shared/components/Elements/Tooltip/Tooltip";

interface IConversionProps {
  clickConversion: number;
  drivenSales: string[];
}

const Conversion: React.FC<IConversionProps> = (props) => {
  return (
    <div className={["row", classes.Conversion].join(" ")}>
      {props.clickConversion ? (
        <div className="column-50">
          <div className={classes.ClickConversion}>
            <span className={classes.Number}>{props.clickConversion}</span>{" "}
            <Tooltip
              showBorderBottom
              position="Top"
              text="Số lần khách hàng xem sản phẩm sau khi đọc đánh giá này từ pop-up"
            >
              click xem sản phẩm 
            </Tooltip>
          </div>
        </div>
      ) : null}
      {props.drivenSales.length ? (
        <div className="column-50">
          <div className={classes.SaleConversion}>
            <div>
              <span className={classes.Number}>{props.drivenSales.length}</span>{" "}
              <Tooltip
                showBorderBottom
                position="Top"
                text="Số đơn hàng sau khi khách hàng click xem sản phẩm"
              >
                đơn hàng
              </Tooltip>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Conversion;
