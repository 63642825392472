import React, { useState } from "react";
import Button from "../../../../shared/components/Elements/Button/Button";
import LoadingScreenHandler from "../../../../shared/components/Elements/LoadingScreenHandler/LoadingScreenHandler";
import Modal from "../../../../shared/components/Elements/Modal/Modal";
import Instruction from "./Instruction/Instruction";
import axios from "axios";
import classes from "./UpdateAssets.module.css";

const UpdateAssets = () => {
  const [requestStatus, setRequestStatus] = useState({
    isError: false,
    isLoading: false,
    isSuccess: false,
  });
  const [isShowInstruction, setIsShowInstruction] = useState(false);

  const onClickHandler = () => {
    setRequestStatus({ isLoading: true, isError: false, isSuccess: false });
    axios({
      method: "POST",
      url: "/client/settings/updateAssets",
    })
      .then(() => {
        setRequestStatus({ isLoading: false, isError: false, isSuccess: true });
      })
      .catch(() => {
        setRequestStatus({ isLoading: false, isError: true, isSuccess: false });
      });
  };

  return (
    <div>
      <div>
        <Button onClick={onClickHandler}>Đồng bộ Ảnh Nổi với theme</Button>
        hoặc{" "}
        <span
          className={classes.ShowInstruction}
          onClick={() => {
            setIsShowInstruction(prev => !prev);
          }}
        >
          xem hướng dẫn
        </span>{" "}
        đề đồng bộ thủ công
      </div>
      {isShowInstruction && <Instruction />}
      <LoadingScreenHandler
        loading={requestStatus.isLoading}
        error={requestStatus.isError}
        errorMessage="Gặp sự cố khi đồng bộ với theme hiện tại. Xin vui lòng xem hướng dẫn để đồng bộ thủ công."
      />
      <Modal
        show={requestStatus.isSuccess}
        backdropClicked={() => {
          setRequestStatus((prev) => ({ ...prev, isSuccess: false }));
        }}
      >
        <div>Đồng bộ thành công!</div>
        <div className={classes.CloseModalBtn}>
          <Button
            size="small"
            onClick={() => {
              setRequestStatus((prev) => ({ ...prev, isSuccess: false }));
            }}
          >
            Đóng
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default UpdateAssets;
