import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IStoreState } from "../../../../../store/reducers";
import * as actions from "../../../../../store/actions/index";
import DataCard from "../DataCard/DataCard";
import classes from "./DrivenSales.module.css";
import TimeSelection from "../TimeSelection/TimeSelection";
import IncludedConversion from "./IncludedConversion/IncludedConversion";
import vndFormat from "../../../../../shared/utils/vndFormat";

const DrivenSales = () => {
  const { overallDrivenSales, loading, error } = useSelector(
    (state: IStoreState) => state.overallDrivenSales
  );
  const [dateRange, setDateRange] = useState<"all" | "30" | "90">("all");
  const [content, setContent] = useState<JSX.Element | null>(null);
  const [number, setNumber] = useState<number | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchOverallDrivenSales());
  }, [dispatch]);

  useEffect(() => {
    if (overallDrivenSales) {
      const {
        retention,
        conversion,
        showPopup,
        isDiscount,
      } = overallDrivenSales;
      const retentionElement = (
        <IncludedConversion
          numberOfOrders={retention.numberOfOrders}
          tooltip="Ảnh Nổi code"
          tooltipText="Số đơn hàng sử dụng code sau khi viết đánh giá"
          revenue={retention.revenue}
          warningText={
            !isDiscount
              ? 'Vào cài đặt, bật "Giảm giá cho những đánh giá có hình ảnh" để khuyến khích khách hàng viết đánh giá và mua hàng'
              : undefined
          }
        />
      );

      const conversionElement = (
        <IncludedConversion
          numberOfOrders={conversion.numberOfOrders}
          tooltip="Ảnh Nổi pop-up"
          tooltipText="Số đơn hàng sau khi tương tác với Ảnh Nổi popup"
          revenue={conversion.revenue}
          warningText={
            !showPopup
              ? 'Vào cài đặt, hiện "Pop-up" để khách hàng có thể tương tác với những đánh giá nổi bật'
              : undefined
          }
        />
      );
      const totalRevenue = retention.revenue + conversion.revenue;

      setContent(
        <div>
          <div className={classes.TotalRevenue}>
            {vndFormat.format(totalRevenue)}
          </div>
          <div>Bao gồm:</div>
          <div className="row">
            <div className="column-50">{retentionElement}</div>
            <div className="column-50">{conversionElement}</div>
          </div>
        </div>
      );
      setNumber(retention.numberOfOrders + conversion.numberOfOrders);
    }
  }, [overallDrivenSales]);

  const fetchDrivenSalesInDateRange = (dateRange: "30" | "90" | "all") => {
    if (dateRange === "all") {
      dispatch(actions.fetchOverallDrivenSales());
    } else {
      dispatch(actions.fetchOverallDrivenSales(dateRange));
    }
  };

  return (
    <DataCard
      number={number}
      numberMeaning="Đơn hàng từ Ảnh Nổi"
      content={content}
      loading={loading}
      error={error}
      footer={
        <TimeSelection
          value={dateRange}
          onChange={(e) => {
            fetchDrivenSalesInDateRange(e.target.value as "30" | "90" | "all");
            setDateRange(e.target.value as "30" | "90" | "all");
          }}
        />
      }
    />
  );
};

export default DrivenSales;
