import { ActionTypes } from "./types/actionTypes";
import axios from "axios";
import { Dispatch } from "redux";
import { IOverallDrivenSales } from "../reducers/overallDrivenSales";
import * as OverallDrivenSalesActionTypes from "./types/overallDrivenSalesActions";

const requestStart: () => OverallDrivenSalesActionTypes.IOverallDrivenSalesRequestStart = () => ({
  type: ActionTypes.OVERALL_DRIVEN_SALES_REQUEST_START,
});

const requestFail: () => OverallDrivenSalesActionTypes.IOverallDrivenSalesRequestFail = () => ({
  type: ActionTypes.OVERALL_DRIVEN_SALES_REQUEST_FAIL,
});

const fetchOverallDrivenSalesSuccess = (
  overallDrivenSales: IOverallDrivenSales
): OverallDrivenSalesActionTypes.IOverallDrivenSalesRequestSuccess => {
  return {
    type: ActionTypes.OVERALL_DRIVEN_SALES_FETCH_SUCCESS,
    overallDrivenSales,
  };
};

export const fetchOverallDrivenSales = (dateRange?: "30" | "90") => {
  return (dispatchEvent: Dispatch) => {
    dispatchEvent(requestStart());
    axios
      .get("/client/overall/drivenSales" + (dateRange ? `?dateRange=${dateRange}` : ""))
      .then((res) => {
        dispatchEvent(fetchOverallDrivenSalesSuccess(res.data));
      })
      .catch((e) => dispatchEvent(requestFail()));
  };
};
