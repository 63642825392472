import { ActionTypes } from "../actions/types/actionTypes";
import { LatestReviewsActions } from "../actions/types/latestReviewsActions";
import updateObject from "../../shared/utils/updateObject";

export interface IReview {
  _id: string;
  isVerified: boolean;
  reviewer: string;
  feedback: string;
  starRating: 1 | 2 | 3 | 4 | 5;
  email: string;
  product: {
    title: string;
    url: string;
  };
  createdAt: string;
  isHidden: boolean;
  clickConversion: number;
  drivenSales: string[];
  reply?: string;
  image?: {
    image500Url: string;
    image720Url: string;
    ratio: number;
  };
  video?: {
    url: string;
    thumbnailUrl: string;
    ratio: number;
  };
}

export interface ILatestReviewsReducers {
  numberOfFound: number | null;
  latestReviews: IReview[] | null;
  loading: boolean;
  error: boolean;
}

const initalState: ILatestReviewsReducers = {
  numberOfFound: null,
  latestReviews: null,
  loading: false,
  error: false,
};

const reducer = (
  state = initalState,
  action: LatestReviewsActions
): ILatestReviewsReducers => {
  switch (action.type) {
    case ActionTypes.LATEST_REVIEWS_REQUEST_START:
      return updateObject(state, { loading: true, error: false });
    case ActionTypes.LATEST_REVIEWS_REQUEST_FAIL:
      return updateObject(state, { loading: false, error: true });

    case ActionTypes.LATEST_REVIEWS_REQUEST_SUCCESS:
      return updateObject(state, {
        numberOfFound: action.numberOfFound,
        latestReviews: action.latestReviews,
        loading: false,
        error: false,
      });

    case ActionTypes.UPDATE_REVIEW_SUCCESS:
      const updatedReviewsArray = state.latestReviews!.map((review) => {
        if (review._id === action.updatedReview._id) {
          return action.updatedReview;
        }
        return review;
      });
      return updateObject(state, {
        latestReviews: updatedReviewsArray,
        loading: false,
        error: false,
      });

    case ActionTypes.DELETE_REVIEW_SUCCESS:
      return updateObject(state, {
        latestReviews: state.latestReviews!.filter(
          (review) => review._id !== action.deletedReviewId
        ),
        loading: false,
        error: false,
      });

    case ActionTypes.MORE_REVIEWS_REQUEST_SUCCESS:
      return updateObject(state, {
        latestReviews: state.latestReviews!.concat(action.moreReviews),
        loading: false,
        error: false,
      });
    default:
      return state;
  }
};

export default reducer;
